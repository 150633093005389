import { onMounted } from "vue";

export const useGravityForm = (props) => {
    const { $toast } = useNuxtApp();
    const router = useRouter();

    const isLoading = ref(true);
    const showContactForm = ref(true);
    const formData = ref(null);
    const formResponse = ref(null);
    const confirmationMessage = ref(null);
    const errors = ref([]);
    const validationMessages = ref({});
    const enteredData = ref({ fields: [] });

    const isFieldVisible = (field) => {
        if (!field.conditionalLogic || !field.conditionalLogic.enabled) {
            return true;
        }

        const { logicType, rules } = field.conditionalLogic;

        const results = rules.map((rule) => {
            const targetField = enteredData.value.fields.find((f) => f.name === `input_${rule.fieldId}`);
            const targetValue = targetField ? targetField.value : null;

            if (rule.operator === "is") {
                return targetValue === rule.value;
            } else if (rule.operator === "isnot") {
                return targetValue !== rule.value;
            } else if (rule.operator === "greater_than") {
                return Number(targetValue) > Number(rule.value);
            } else if (rule.operator === "less_than") {
                return Number(targetValue) < Number(rule.value);
            } else if (rule.operator === "contains") {
                return targetValue?.includes(rule.value);
            } else if (rule.operator === "starts_with") {
                return targetValue?.startsWith(rule.value);
            } else if (rule.operator === "ends_with") {
                return targetValue?.endsWith(rule.value);
            } else {
                return false;
            }
        });

        if (logicType === "all") {
            return results.every(Boolean);
        } else {
            return results.some(Boolean);
        }
    };

    const getContactForm = async () => {
        try {
            const { data, error } = await useFetch(`/api/wp/forms/${props.form_id}`);

            if (error.value) {
                throw createError({ statusCode: 404, statusMessage: "Page Not Found" });
            }

            if (!data.value) {
                throw createError({ statusCode: 500, statusMessage: "Invalid server response" });
            }

            formData.value = data.value;
            isLoading.value = false;

            await updateRefererFields(data.value);
        } catch (err) {
            console.error("Fetch error:", err);
        }
    };

    const updateRefererFields = async (data) => {
        data.fields.forEach((field) => {
            if (field.type === "hidden" && field.defaultValue === "{embed_url}") {
                const name = `input_${field.id}`;
                const existing = enteredData.value.fields.find((f) => f.name === name);

                if (existing) {
                    existing.value = window.location.href;
                } else {
                    enteredData.value.fields.push({ name, value: window.location.href });
                }
            }
        });
    };

    const stripHtmlTags = (html) => {
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = html;
        return tempDiv.textContent || tempDiv.innerText || "";
    };

    const getConfirmationMessage = (formConfirmations) => {
        const defaultConfirmationMessage = "Thank you for your submission.";

        if (!formConfirmations) {
            return defaultConfirmationMessage;
        }

        let confirmation = Object.values(formConfirmations).find((c) => c.isDefault);

        for (const confirmationObject of Object.values(formConfirmations)) {
            const { conditionalLogic, message } = confirmationObject;

            if (!conditionalLogic?.rules) {
                continue;
            }

            const matches = conditionalLogic.rules.every((rule) => {
                const val = getFieldValue(`input_${rule.fieldId}`);

                if (rule.operator === "is") {
                    return val === rule.value;
                } else if (rule.operator === "isnot") {
                    return val !== rule.value;
                } else if (rule.operator === ">") {
                    return val > rule.value;
                } else if (rule.operator === "<") {
                    return val < rule.value;
                } else if (rule.operator === "contains") {
                    return val?.includes(rule.value);
                } else if (rule.operator === "starts_with") {
                    return val?.startsWith(rule.value);
                } else if (rule.operator === "ends_with") {
                    return val?.endsWith(rule.value);
                } else {
                    return false;
                }
            });

            if (matches) {
                return message;
            }
        }

        return confirmation?.message || defaultConfirmationMessage;
    };

    const submitForm = async () => {
        errors.value = [];
        validationMessages.value = {};

        try {
            const formDataPayload = new FormData();
            formDataPayload.append("form_id", props.form_id);

            enteredData.value.fields.forEach((field) => {
                // Check if field.value is an array (multiple file input)
                if (Array.isArray(field.value)) {
                    field.value.forEach((fileObj) => {
                        formDataPayload.append(field.name, fileObj.value, fileObj.filename);
                    });
                } else if (field.value && typeof field.value === "object" && field.value.type === "file") {
                    // Single file input
                    formDataPayload.append(field.name, field.value.value, field.value.filename);
                } else {
                    // Normal (non-file) input
                    formDataPayload.append(field.name, field.value);
                }
            });

            const response = await fetch("/api/wp/forms/submit", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                },
                body: formDataPayload,
            });

            const json = await response.json();

            if (!response.ok || json?.is_valid === false) {

                console.error("Form submission failed:", {
                    status: response.status,
                    statusText: response.statusText,
                    json,
                });

                validationMessages.value = json.validation_messages || {};
                errors.value = Object.values(validationMessages.value).flat();

                if (errors.value.length) {
                    $toast.error(errors.value[0]);
                } else {
                    $toast.error("Form submission failed. Please check the errors and try again.");
                }

                return;
            }

            confirmationMessage.value = getConfirmationMessage(formData.value?.confirmations);
            formResponse.value = json.confirmation_message;
            showContactForm.value = false;

            if (props.redirect_url) {
                $toast.success(stripHtmlTags(confirmationMessage.value));
                router.push(props.redirect_url);
            } else {
                $toast.success(stripHtmlTags(confirmationMessage.value));
            }
        } catch (err) {
            console.error("Unexpected error:", err);
            errors.value = ["An unexpected error occurred. Please try again."];
            $toast.error("An unexpected error occurred. Please try again.");
        }
    };

    const getFieldValue = (name) => {
        const field = enteredData.value.fields.find((f) => f.name === name);

        if (field) {
            return field.value;
        } else {
            return "";
        }
    };

    const updateFieldValue = (name, value) => {
        const field = enteredData.value.fields.find((f) => f.name === name);

        if (field) {
            field.value = value;
        } else {
            enteredData.value.fields.push({ name, value });
        }
    };

    const handleCheckboxChange = (name, value) => {
        const field = enteredData.value.fields.find((f) => f.name === name);

        if (field) {
            if (field.value === value) {
                field.value = "";
            } else {
                field.value = value;
            }
        } else {
            enteredData.value.fields.push({
                name,
                value,
            });
        }
    };

    const isCheckboxChecked = (name, value) => {
        const field = enteredData.value.fields.find((f) => f.name === name);

        if (field) {
            return field.value === value;
        } else {
            return false;
        }
    };

    const handleRadioChange = (name, value) => {
        updateFieldValue(name, value);
    };

    // Single file change handler
    const handleFileChange = (fieldName, file) => {
        const fileData = {
            value: file, // the actual File object
            type: "file",
            filename: file.name,
        };

        // Find if the field already exists
        const existingIndex = enteredData.value.fields.findIndex((f) => f.name === fieldName);

        if (existingIndex > -1) {
            enteredData.value.fields[existingIndex].value = fileData;
        } else {
            enteredData.value.fields.push({ name: fieldName, value: fileData });
        }
    };

    // Multiple file change handler
    const handleMultipleFileChange = (fieldName, files) => {
        // debugger;

        // Create an array of file objects from the FileList
        const fileArray = Array.from(files).map((file) => ({
            value: file,
            type: "file",
            filename: file.name,
        }));

        const existingIndex = enteredData.value.fields.findIndex((f) => f.name === fieldName);

        if (existingIndex > -1) {
            enteredData.value.fields[existingIndex].value = fileArray;
        } else {
            enteredData.value.fields.push({ name: fieldName, value: fileArray });
        }
    };

    const toggleTextarea = (e) => {
        const $target = e.currentTarget;
        $target.nextElementSibling.classList.add("is-active");
        $target.remove();
    };

    const getValidationMessage = (inputId) => {
        return validationMessages.value[inputId] || "";
    };

    onMounted(() => {
        setTimeout(getContactForm, 500);
    });

    return {
        isLoading,
        showContactForm,
        formData,
        formResponse,
        confirmationMessage,
        errors,
        validationMessages,
        enteredData,
        isFieldVisible,
        getFieldValue,
        updateFieldValue,
        submitForm,
        handleCheckboxChange,
        isCheckboxChecked,
        handleRadioChange,
        handleFileChange,
        handleMultipleFileChange,
        toggleTextarea,
        getValidationMessage,
    };
};